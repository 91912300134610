import gql from "graphql-tag";

export const PickContainerFragmentGql = gql`
  fragment PickContainer on PickContainer {
    id
    name
    users {
      id
      nickName
    }
  }
`;
