import gql from "graphql-tag";

export const UserFragmentGql = gql`
  fragment User on User {
    id
    email
    nickName
  }
`;

export const MinimalUserFragmentGql = gql`
  fragment MinimalUser on MinimalUser {
    id
    nickName
  }
`;
