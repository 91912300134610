import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { UserContextProvider } from "./Contexts/UserContext";
import { PickPalApolloProvider } from "./Providers/PickPalApolloProvider";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./index.scss";
import "semantic-ui-css/semantic.min.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <PickPalApolloProvider>
    <UserContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </UserContextProvider>
  </PickPalApolloProvider>
);

reportWebVitals(console.log);
