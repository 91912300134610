import React, { Suspense, useContext, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Loader, Segment } from "semantic-ui-react";

import { UserContext } from "./Contexts/UserContext";
import MainMenu from "./UI/Components/MainMenu/MainMenuComponent";

import "./App.scss";

const WelcomePage = React.lazy(() => import("./Pages/Welcome/WelcomePage"));
const StartPage = React.lazy(() => import("./Pages/Start/StartPage"));
const SetNickNamePage = React.lazy(
  () => import("./Pages/SetNickName/SetNickNamePage")
);
const DecisionFormPage = React.lazy(
  () => import("./Pages/DecisionForm/DecisionFormPage")
);
const DecisionStartPage = React.lazy(
  () => import("./Pages/DecisionStart/DecisionStartPage")
);
const InviteUserPage = React.lazy(
  () => import("./Pages/InviteUser/InviteUserPage")
);
const BattleInfinitePage = React.lazy(
  () => import("./Pages/BattleInfinite/BattleInfinitePage")
);
const HighscorePage = React.lazy(
  () => import("./Pages/Highscore/HighscorePage")
);
const SettingsPage = React.lazy(() => import("./Pages/Settings/SettingsPage"));
const RemoveUserPage = React.lazy(
  () => import("./Pages/RemoveUser/RemoveUserPage")
);
const PrivacyPolicyPage = React.lazy(
  () => import("./Pages/PrivacyPolicy/PrivacyPolicyPage")
);

export const App: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (!userContext) {
      return;
    }

    if (
      !userContext.isSignedIn &&
      window.location.pathname !== "/" &&
      window.location.pathname !== "/privacy-policy"
    ) {
      navigate("/?returnUrl=" + window.location.pathname, {
        replace: true,
      });
      return;
    }

    if (
      userContext.isSignedIn &&
      !userContext.user.nickName &&
      window.location.pathname !== "/settings/nickname"
    ) {
      navigate("/settings/nickname?returnUrl=" + window.location.pathname, {
        replace: true,
      });
      return;
    }

    if (userContext.isSignedIn && window.location.pathname === "/") {
      navigate("/start", { replace: true });
    }
  }, [navigate, userContext, userContext?.user]);

  return (
    <>
      <MainMenu />
      <Segment inverted={userContext?.isDarkMode} basic padded>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route
              path="/"
              element={
                <WelcomePage
                  returnUrl={urlParams.get("returnUrl") ?? undefined}
                />
              }
            />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/start" element={<StartPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route
              path="/settings/nickname"
              element={
                <SetNickNamePage
                  returnUrl={urlParams.get("returnUrl") ?? undefined}
                />
              }
            />
            <Route path="/settings/delete" element={<RemoveUserPage />} />
            <Route path="/decision/new" element={<DecisionFormPage />} />
            <Route
              path="/decision/:pickContainerId"
              element={<DecisionStartPage />}
            />
            <Route
              path="/decision/:pickContainerId/invite"
              element={<InviteUserPage />}
            />
            <Route
              path="/decision/:pickContainerId/highscore"
              element={<HighscorePage />}
            />
            <Route
              path="/decision/:pickContainerId/battle/infinite"
              element={<BattleInfinitePage />}
            />
            <Route path="*" element={<div>404: Page not found</div>} />
          </Routes>
        </Suspense>
      </Segment>
    </>
  );
};

export default App;
