import { useCallback, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import {
  Divider,
  Dropdown,
  Header,
  Icon,
  Image,
  Menu,
} from "semantic-ui-react";

import { UserContext } from "src/Contexts/UserContext";
import { useBackendUrl } from "src/Hooks/BackendUrlHook";

const MainMenu = () => {
  const userContext = useContext(UserContext);
  const backendUrl = useBackendUrl();

  const toggleDarkMode = useCallback(() => {
    userContext?.setIsDarkMode((prev: boolean) => !prev);
  }, [userContext]);

  const { mainMenuItems, rightMenuItems } = useMemo(() => {
    const selectedPickContainer = userContext?.pickContainers.find(
      (pickContainer) => window.location.pathname.includes(pickContainer.id)
    );

    if (userContext?.isSignedIn) {
      return {
        mainMenuItems: (
          <Dropdown item text={selectedPickContainer?.name ?? "Decisions"}>
            <Dropdown.Menu>
              {userContext.pickContainers.slice(0, 5).map((pickContainer) => (
                <Dropdown.Item
                  key={`menu-decision-${pickContainer.id}`}
                  as={Link}
                  to={`/decision/${pickContainer.id}`}
                  active={pickContainer.id === selectedPickContainer?.id}
                >
                  {pickContainer.name}
                </Dropdown.Item>
              ))}
              <Dropdown.Item
                as={Link}
                to={`/decision/new`}
                icon="add"
                text="Add new decision"
                active={window.location.pathname === "/decision/new"}
              />
            </Dropdown.Menu>
          </Dropdown>
        ),
        rightMenuItems: (
          <Dropdown
            item
            icon="bars"
            position="right"
            style={{ display: "flex", marginLeft: "auto" }}
          >
            <Dropdown.Menu direction="left">
              <Header textAlign="center" inverted={userContext?.isDarkMode}>
                {userContext.user.nickName}
              </Header>
              <Divider></Divider>
              <Dropdown.Item
                as={Link}
                to="/settings"
                active={window.location.pathname === "/settings"}
              >
                <Icon name="setting" />
                Settings
              </Dropdown.Item>
              <Dropdown.Item onClick={toggleDarkMode}>
                <Icon name={userContext.isDarkMode ? "sun" : "moon"} /> Toggle
                darkmode
              </Dropdown.Item>
              <Dropdown.Item as="a" href={backendUrl + "/api/user/logout"}>
                <Icon name="sign-out" /> Sign out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      };
    }

    return {
      mainMenuItems: <></>,
      rightMenuItems: (
        <Dropdown
          item
          icon="bars"
          position="right"
          style={{ display: "flex", marginLeft: "auto" }}
        >
          <Dropdown.Menu direction="left">
            <Dropdown.Item onClick={toggleDarkMode}>
              <Icon name={userContext?.isDarkMode ? "sun" : "moon"} /> Toggle
              darkmode
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    backendUrl,
    userContext?.isSignedIn,
    userContext?.pickContainers,
    userContext?.isDarkMode,
    window.location.pathname,
  ]);

  return (
    <>
      <Menu borderless attached inverted={userContext?.isDarkMode} size="huge">
        <Menu.Item as={Link} header to="/start">
          <Image size="tiny" src="/images/logo-wide.png" />
        </Menu.Item>
        {mainMenuItems}
        {rightMenuItems}
      </Menu>
      <Divider fitted />
    </>
  );
};

export default MainMenu;
