import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { PropsWithChildren } from "react";
import { useQuery } from "@apollo/client";
import { Loader } from "semantic-ui-react";

import {
  GetInitDataQuery,
  GetInitDataQueryVariables,
  PickContainerFragment,
  UserFragment,
} from "src/codegen-types";
import { GetInitDataGql } from "src/GraphQL/Queries/UserQueries";
import { useIsDarkModeDefault } from "src/Hooks/IsDarkModeDefaultHook";
import { useEffectSkipFirstRender } from "src/Hooks/SkipFirstRenderHook";

export interface IUserContext {
  isSignedIn: boolean;
  isDarkMode: boolean;
  setIsDarkMode: Dispatch<SetStateAction<boolean>>;
  user: UserFragment;
  pickContainers: PickContainerFragment[];
}

export const UserContext = createContext<IUserContext | undefined>(undefined);

export const UserContextProvider: React.FunctionComponent<PropsWithChildren> = (
  props
) => {
  const isDarkModeDefault = useIsDarkModeDefault();
  const [networkRequestDone, setNetworkRequestDone] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(isDarkModeDefault);
  const [user, setUser] = useState<UserFragment>();
  const [pickContainers, setPickContainers] = useState<PickContainerFragment[]>(
    []
  );

  const networkRequestCompleted = useCallback((data: GetInitDataQuery) => {
    const timeout = setTimeout(() => {
      document
        .querySelector("#welcome-panel")
        ?.setAttribute("style", "display:none");

      setIsSignedIn(data.user !== undefined);
      setUser(data.user);
      setPickContainers(data.pickContainers);
      setNetworkRequestDone(true);
    }, 800);

    return () => clearTimeout(timeout);
  }, []);

  const networkRequestError = useCallback(() => {
    const timeout = setTimeout(() => {
      document
        .querySelector("#welcome-panel")
        ?.setAttribute("style", "display:none");

      setUser(undefined);
      setIsSignedIn(false);
      setNetworkRequestDone(true);
    }, 800);

    return () => clearTimeout(timeout);
  }, []);

  useQuery<GetInitDataQuery, GetInitDataQueryVariables>(GetInitDataGql, {
    fetchPolicy: "network-only",
    onCompleted: networkRequestCompleted,
    onError: networkRequestError,
  });

  const getInitDataResultCache = useQuery<
    GetInitDataQuery,
    GetInitDataQueryVariables
  >(GetInitDataGql, { fetchPolicy: "cache-only", skip: user === undefined });

  useEffectSkipFirstRender(() => {
    setIsSignedIn(getInitDataResultCache.data?.user !== undefined);
    setUser(getInitDataResultCache.data?.user);
    setPickContainers(getInitDataResultCache.data?.pickContainers ?? []);
  }, [getInitDataResultCache.data]);

  useEffect(() => {
    document
      .querySelector("#welcome-panel")
      ?.setAttribute("class", isDarkMode ? "ui inverted segment" : "not-dark");

    document
      .querySelector("body")
      ?.setAttribute(
        "style",
        `background-color: ${
          isDarkMode ? "#1b1c1d !important" : "#fff !important"
        }`
      );

    window.localStorage.setItem("isDarkMode", isDarkMode.toString());
  }, [isDarkMode]);

  return !networkRequestDone ? (
    <Loader active inverted={isDarkMode} />
  ) : (
    <UserContext.Provider
      value={{
        isSignedIn: isSignedIn,
        isDarkMode: isDarkMode,
        setIsDarkMode: setIsDarkMode,
        user: user ?? {
          __typename: "User",
          id: "",
          email: "",
        },
        pickContainers: pickContainers,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
