import gql from "graphql-tag";

import { PickContainerFragmentGql } from "../Fragments/PickContainerFragments";
import {
  MinimalUserFragmentGql,
  UserFragmentGql,
} from "../Fragments/UserFragments";

export const GetUserGql = gql`
  query GetUser {
    user {
      ...User
    }
  }
  ${UserFragmentGql}
`;

export const GetInitDataGql = gql`
  query GetInitData {
    user {
      ...User
    }
    pickContainers {
      ...PickContainer
    }
  }
  ${UserFragmentGql}
  ${PickContainerFragmentGql}
`;

export const GetMinimalUserGql = gql`
  query GetOtherUser($userId: Guid!) {
    minimalUser(id: $userId) {
      ...MinimalUser
    }
  }
  ${MinimalUserFragmentGql}
`;

export const GenerateOtpGql = gql`
  query GenerateOtp(
    $email: String!
    $returnUrl: String!
    $policiesAccepted: Boolean!
    $rememberMe: Boolean!
  ) {
    generateOtp(
      email: $email
      returnUrl: $returnUrl
      policiesAccepted: $policiesAccepted
      rememberMe: $rememberMe
    )
  }
`;
