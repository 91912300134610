import { useMemo } from "react";

export const useBackendUrl = () => {
  return useMemo(() => {
    if (process.env.NODE_ENV === "development") {
      return "https://localhost:7162";
    }

    return "";
  }, []);
};
