import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { LocalStorageWrapper, persistCache } from "apollo3-cache-persist";

import fragmentMatcher from "src/codegen-fragmentmatcher";
import { StrictTypedTypePolicies } from "src/codegen-types";

export const PickPalApolloProvider: React.FunctionComponent<
  PropsWithChildren
> = (props) => {
  const [cachePersisted, setCachePersisted] = useState(false);

  const { checkVersionLink, httpLink, errorLink, cache } = useMemo(() => {
    const typePolicies: StrictTypedTypePolicies = {};

    const httpLink = createHttpLink({
      uri: "/api/graphql",
    });

    const errorLink = onError(({ graphQLErrors }) => {
      if (
        graphQLErrors?.some(
          (error) => error.extensions["code"] === "ACCESS_DENIED"
        )
      ) {
        if (
          window.location.pathname !== "/" &&
          window.location.pathname !== "/privacy-policy"
        ) {
          window.location.href = `/?returnUrl=${window.location.pathname}`;
        }
      }
    });

    const checkVersionLink = new ApolloLink((operation, forward) => {
      return forward(operation).map((response) => {
        const context = operation.getContext();
        const {
          response: { headers },
        } = context;

        if (headers) {
          const localVersion = window.localStorage.getItem("version");
          const serverVersion = headers.get("version");

          if (localVersion !== serverVersion) {
            window.localStorage.removeItem("apollo-cache-persist");
            window.localStorage.setItem("version", serverVersion);
            window.location.reload();
          }
        }

        return response;
      });
    });

    const cache = new InMemoryCache({
      possibleTypes: fragmentMatcher.possibleTypes,
      typePolicies: typePolicies,
    });

    return { checkVersionLink, httpLink, errorLink, cache };
  }, []);

  useEffect(() => {
    const persistCachAsync = async () => {
      await persistCache({
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
      });

      setCachePersisted(true);
    };

    persistCachAsync();
  }, [cache]);

  if (!cachePersisted) {
    return null;
  }

  const client = new ApolloClient({
    cache: cache,
    link: ApolloLink.from([checkVersionLink, errorLink, httpLink]),
    defaultOptions: { watchQuery: { fetchPolicy: "cache-and-network" } },
  });

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
