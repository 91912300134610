import { useMemo } from "react";

export const useIsDarkModeDefault = () => {
  return useMemo(() => {
    const storageValue = window.localStorage.getItem("isDarkMode");
    return storageValue !== null
      ? storageValue === "true"
      : window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches;
  }, []);
};
