import { useEffect, useRef } from "react";

export function useEffectSkipFirstRender(
  effect: React.EffectCallback,
  deps?: React.DependencyList
) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      effect();
    } else {
      didMountRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
